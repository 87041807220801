<template>
  <base-layout>
    <AddEditTopupOffer @refresh="$store.dispatch('fsTable/fetchData')" />
    <content-section :spacing="false">
      <div class="content-spacing">
        <div class="grid grid-cols-1 mb-10 md:grid-cols-2">
          <TitlePlus
            :title="$t('components.topupOfferManagement.headline')"
            @plus="add"
            :hide-plus="$acl.canNotCreate('pass')"
          />

          <div class="flex md:justify-end">
            <DateRangePicker
              @apply-date="onApplyFilterDateRange"
              @cancel-date="onApplyFilterDateRange"
            />
          </div>
        </div>
      </div>
      <div
        v-if="$acl.canNotView('pass')"
        class="py-5 font-bold text-center text-gray-600"
      >
        {{ $t('components.acl.doNotHavePermission') }}
      </div>

      <FSTable
        v-else
        :fst-id="`TopupOfferIndex`"
        :headers="tableHeaders"
        :endpoint="indexDataEndpoint"
        @meta="(e) => (indexMetaData = e)"
      >
        <template #topLeft="{ slotWidth }">
          <keep-alive>
            <FSTableFilter
              :fst-id="`TopupOfferIndex`"
              :slot-width="slotWidth"
              :options="getFilteredItems"
            />
          </keep-alive>
        </template>

        <template #default="{ data }">
          <FSTableRow
            v-for="(item, itemIndex) in data"
            :key="itemIndex"
            text-fallback-always
          >
            <FSTableRowItem>
              {{ $truncate(item.id, -5) }}
            </FSTableRowItem>
            <FSTableRowItem>
              <div
                class="flex items-center gap-2 flex-wrap"
                v-if="item.assigned_fleets && item.assigned_fleets.length > 0"
              >
                <RouterLink
                  class="text-blue-600 "
                  v-for="(fleet, key) in item.assigned_fleets"
                  :key="key"
                  :to="{ name: 'ViewFleetProfile', params: { id: fleet.id } }"
                  target="_blank"
                  >{{ fleet.name }}</RouterLink
                >
              </div>
            </FSTableRowItem>
            <FSTableRowItem
              :text="`${item.currency.symbol}${item.price}`"
              :class-name="`text-oGreen`"
            />

            <FSTableRowItem
              :text="`${item.riding_time_seconds / 60} mins`"
              :class-name="`text-oOrange`"
            />
            <FSTableRowItem :text="getDate(item.start_date)" />

            <FSTableRowItem :text="getDate(item.end_date)" />

            <FSTableRowItem
              :text="
                `${item.validity_duration} ${
                  item.validity_duration_unit_display_text
                }${item.validity_duration > 1 ? 's' : ''}`
              "
            />

            <FSTableRowItem>
              <XStatus
                :text="item.is_active ? 'Active' : 'Inactive'"
                :variant="getItemVariant(item.is_active)"
                profile="payment"
              />
            </FSTableRowItem>

            <FSTableRowItem>
              <div class="flex items-center gap-1">
                <OtoEditIcon @click="edit(item)" />
                <MoreActions
                  :data="item"
                  @refresh="$store.dispatch('fsTable/fetchData')"
                />
              </div>
            </FSTableRowItem>
          </FSTableRow>
        </template>
      </FSTable>
    </content-section>
  </base-layout>
</template>

<script>
import BaseLayout from '@/views/shared/BaseLayout.vue'
import ContentSection from '@/components/layout/ContentSection'
import TitlePlus from '@/components/ui/TitlePlus'
import OtoEditIcon from '@/components/ui/OtoEditIcon'
import AddEditTopupOffer from '@/views/topup-offer/AddEditTopupOffer.vue'
import MoreActions from '@/views/topup-offer/MoreActions.vue'

import {
  FSTable,
  FSTableRow,
  FSTableRowItem,
  FSTableFilter,
  onApplyFilterDateRange,
} from '@/components/fs-table'

import { TopupOfferConfig } from '@/config/TopupOfferConfig'

import { EventBus } from '@/utils/EventBus'
import DateRangePicker from '@/components/picker/date-range/DateRangePicker'
import { getDate } from '@/utils'

import XStatus from '@/components/badge/XStatus'

export default {
  name: 'TopupOfferIndex',
  components: {
    BaseLayout,
    ContentSection,
    TitlePlus,

    OtoEditIcon,
    DateRangePicker,
    XStatus,

    FSTable,
    FSTableRow,
    FSTableRowItem,
    FSTableFilter,
    AddEditTopupOffer,
    MoreActions,
  },
  props: {
    filterItems: {
      type: Array,
      default: () => [],
    },
  },
  async created() {
    await this.generateFilterOptions()
  },
  computed: {
    currencySymbol() {
      return this?.$org?.default_currency?.symbol || '$'
    },
    getFilteredItems() {
      if (this.filterItems.length) {
        if (this.filterItems.length === this.filterOptions.length) {
          let propFilterItems = this.filterItems
          propFilterItems.push({}) //-for updating filteritems into rendering moment
          return propFilterItems
        } else {
          return this.filterItems
        }
      } else {
        return this.filterOptions
      }
    },
  },
  data() {
    return {
      indexMetaData: {
        summary: {
          // total_pass_user: 0,
          // earning_from_pass: 0,
        },
      },
      indexDataEndpoint: TopupOfferConfig.api.index,
      tableHeaders: [
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.id'
          )}`,
          width: '5%',
          sort: null,
        },

        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.fleet'
          )}`,
          width: '15%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.price'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.ridingTime'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.staredtAt'
          )}`,
          width: '10%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.expiredAt'
          )}`,
          width: '10%',
          sort: 'is_active',
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.validity'
          )}`,
          width: '7%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.status'
          )}`,
          width: '5%',
          sort: null,
        },
        {
          text: `${this.$t(
            'components.topupOfferManagement.table.columns.actions'
          )}`,
          width: '5%',
          sort: null,
        },
      ],
      filterOptions: [],
    }
  },
  methods: {
    onApplyFilterDateRange,
    getDate,

    async generateFilterOptions() {
      let filters = []

      if (this.$acl.canView('fleets')) {
        let filterableFleets = []
        await this.$http.get('/dashboard/fleets/?dropdown').then(({ data }) => {
          if (data.data.length) {
            data.data.forEach((fleet) => {
              filterableFleets.push({ text: fleet.name, value: fleet.id })
            })
          }
        })
        filters.unshift({
          key: 'fleet',
          type: 'checkbox',
          input: filterableFleets,
          title: 'Fleet',
        })
        this.filterOptions = filters
      } else {
        this.filterOptions = filters
      }
    },

    add: function() {
      EventBus.$emit(TopupOfferConfig.events.editingData, {})
      dispatchEvent(new Event(TopupOfferConfig.events.sorToggle))
    },
    edit: async function(item) {
      this.$notify(
        {
          group: 'generic',
          type: 'default',
          title: `Processing..`,
          text: 'The action is being executed...',
        },
        600
      )
      await this.$http
        .get(TopupOfferConfig.api.detail(item.id))
        .then((res) => {
          const formData = {
            currency: res.data.currency.id,
            assigned_fleets: res.data.assigned_fleets.map((x) => x.id),
            validity_duration_unit: res.data.validity_duration_unit,
            validity_duration: res.data.validity_duration,
            price: res.data.price,
            riding_time_seconds: `${res.data.riding_time_seconds / 60}`,
            start_date: res.data.start_date.split('T')[0],
            end_date: res.data.end_date.split('T')[0],
            is_active: res.data.is_active,
          }
          EventBus.$emit(TopupOfferConfig.events.editingData, {
            item: formData,
            primaryKey: item.id,
          })
          dispatchEvent(new Event(TopupOfferConfig.events.sorToggle))
        })
        .catch((err) => {
          console.warn({ err })
          this.$notify(
            {
              group: 'generic',
              type: 'error',
              title: `Action failed to execute!`,
              text: 'Failed to retrieve the user. Please try again.',
            },
            5000
          )
        })
    },
    getItemVariant(data) {
      if (data) {
        return 'green'
      } else {
        return 'gray'
      }
    },
  },
}
</script>

<style></style>
