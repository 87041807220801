<template lang="">
  <div class="mt-3 md:mt-0">
    <t-dropdown variant="smActions">
      <template
        #trigger="{
          mousedownHandler,
          focusHandler,
          blurHandler,
          keydownHandler, 
        }"
      >
        <button
          class="text-oDark focus:outline-none"
          aria-label="Task Status Menu"
          aria-haspopup="true"
          @mousedown="mousedownHandler"
          @focus="focusHandler"
          @blur="blurHandler"
          @keydown="keydownHandler"
        >
          <i class="text-sm text-gray-600 fas fa-ellipsis-v" />
        </button>
      </template>

      <template #default="{ hide }">
        <div
          @click="hide()"
          class="py-1 mt-px bg-white rounded-md shadow-md z-200"
        >
          <t-dropdown-item @click="executeAction('delete')" variant="danger">
            Delete
          </t-dropdown-item>
        </div>
      </template>
    </t-dropdown>
  </div>
</template>
<script>
import { TopupOfferConfig } from '@/config/TopupOfferConfig'
export default {
  name: 'TaskMoreActionsDropdown',
  components: {
    TDropdownItem: () => import('@/components/dropdown/TDropdownItem'),
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },

  methods: {
    async executeAction(type) {
      if (type === 'delete') {
        const endpoint = TopupOfferConfig.api.delete(this.data.id)
        console.log('endpoint', endpoint)

        await this.$http
          .delete(endpoint)
          .then((res) => {
            console.log('delete', res.data)

            this.$notify({
              group: 'bottomRight',
              type: `success`,
              title: `Success`,
              text: `Deleted successfully`,
            })
            this.$emit('refresh')
          })
          .catch((err) => {
            console.error('v-catch', { err })

            this.$notify(
              {
                group: 'bottomRight',
                type: 'error',
                title: `Error Occurred [${err.response.status}]`,
                text: err.response.data.message ?? 'Failed to delete.',
              },
              5000
            )
          })

        return
      }
    },
  },
}
</script>
<style lang=""></style>
