var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('slide-over-right',{attrs:{"id":_vm.sorId,"editType":"pass"},on:{"showModal":_vm.showModal}},[_c('loading',{attrs:{"active":_vm.isLoading},on:{"update:active":function($event){_vm.isLoading=$event}}}),_c('div',{staticClass:"flex flex-wrap px-6 pt-12 w-full"},[_c('div',{staticClass:"items-center w-1/2 panel-title header-title"},[_vm._v(" "+_vm._s(_vm.titleText)+" ")])]),_c('div',{staticClass:"px-6 mt-2"},[_c('TopupCard',{attrs:{"ridingDuration":_vm.form.riding_time_seconds,"validityDuration":_vm.form.validity_duration,"validityDurationUnit":_vm.form.validity_duration_unit,"currencySymbol":_vm.currencySymbol,"topupOfferPrice":_vm.form.price,"topupOfferStartDate":_vm.getTopupStartedDate,"topupOfferExpiredDate":_vm.getTopupExpiredDate}}),_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"sm:pb-24 2xl:px-6 2xl:pb-3 sm:px-1 mt-3",attrs:{"enctype":"multipart/form-data"},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.confirmSubmit)}}},[_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.fleetCurrency'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.fleetCurrency'
              ),"placeholder":_vm.getFleetCurrencyPlaceholder,"disabled":_vm.getFleetCurrencyDisableState,"options":_vm.getCurrencyOptions,"isInfoEnabled":false,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.currency),callback:function ($$v) {_vm.$set(_vm.form, "currency", $$v)},expression:"form.currency"}})],1),_c('div',[_c('AppInput',{key:("fleet-" + _vm.counter),attrs:{"type":"richselect","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.assignedFleet'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.assignedFleet'
              ),"placeholder":_vm.getAssignedFleetPlaceholder,"disabled":_vm.getAssignedFleetDisabledState,"options":_vm.getFleetOptions,"isInfoEnabled":false,"hide-search-box":"","rules":"required","multiple":""},model:{value:(_vm.form.assigned_fleets),callback:function ($$v) {_vm.$set(_vm.form, "assigned_fleets", $$v)},expression:"form.assigned_fleets"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
              ),"placeholder":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.placeholder.validityDurationUnit'
              ),"options":[
              { text: 'Hour', value: 0 },
              { text: 'Day', value: 1 } ],"isInfoEnabled":false,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.validity_duration_unit),callback:function ($$v) {_vm.$set(_vm.form, "validity_duration_unit", $$v)},expression:"form.validity_duration_unit"}}),_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.validityDuration'
              ),"placeholder":"e.g. 10","haveSuggestion":false,"step":"1","min":"1","haveUnitText":true,"unitText":_vm.getValidityDurationUnitText,"isInfoEnabled":false},model:{value:(_vm.form.validity_duration),callback:function ($$v) {_vm.$set(_vm.form, "validity_duration", $$v)},expression:"form.validity_duration"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.price'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.price'
              ),"placeholder":"e.g. 10","haveSuggestion":false,"step":"0.1","min":"0","haveUnitText":true,"unitText":_vm.getCurrencySymbol,"isInfoEnabled":false},model:{value:(_vm.form.price),callback:function ($$v) {_vm.$set(_vm.form, "price", $$v)},expression:"form.price"}}),_c('AppInput',{attrs:{"type":"number","rules":"required","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.ridingTime'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.ridingTime'
              ),"placeholder":"e.g. 10","haveSuggestion":false,"step":"1","min":"1","haveUnitText":true,"unitText":"Minute","isInfoEnabled":false},model:{value:(_vm.form.riding_time_seconds),callback:function ($$v) {_vm.$set(_vm.form, "riding_time_seconds", $$v)},expression:"form.riding_time_seconds"}})],1),_c('div',{staticClass:"grid grid-cols-2 gap-4 mt-2"},[_c('AppInput',{attrs:{"type":"date","rules":"required","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.startDate'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.startDate'
              ),"haveSuggestion":false,"isInfoEnabled":false,"min":_vm.minStartDate},model:{value:(_vm.form.start_date),callback:function ($$v) {_vm.$set(_vm.form, "start_date", $$v)},expression:"form.start_date"}}),_c('AppInput',{attrs:{"type":"date","rules":"required","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.expireDate'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.expireDate'
              ),"haveSuggestion":false,"isInfoEnabled":false,"min":_vm.minEndDate},model:{value:(_vm.form.end_date),callback:function ($$v) {_vm.$set(_vm.form, "end_date", $$v)},expression:"form.end_date"}})],1),_c('div',[_c('AppInput',{attrs:{"type":"richselect","name":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.status'
              ),"label":_vm.$t(
                'components.topupOfferManagement.addEdit.steps.topup.title.status'
              ),"options":[
              { text: 'Active', value: true },
              { text: 'Inactive', value: false } ],"isInfoEnabled":false,"hide-search-box":"","rules":"required"},model:{value:(_vm.form.is_active),callback:function ($$v) {_vm.$set(_vm.form, "is_active", $$v)},expression:"form.is_active"}})],1),_c('button',{ref:"submitButton",staticClass:"hidden",attrs:{"type":"submit"}},[_vm._v("Save")])])]}}])}),_c('div',{staticClass:"flex justify-end items-center pr-2 mt-6"},[_c('slide-over-right-trigger',{attrs:{"id":_vm.sorId}},[_c('anchor-button',{attrs:{"variant":"secondary"}},[_vm._v(_vm._s(_vm.$t('components.stepNavigation.cancel')))])],1),_c('t-button',{staticClass:"ml-3",attrs:{"type":"submit"},on:{"click":function($event){return _vm.submit()}}},[_vm._v(_vm._s(_vm.actionButtonText))])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }